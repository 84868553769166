import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import useFetch from '../useFetch';
import { Button, Card, Dropdown, Image, Table } from 'react-bootstrap';

const Dashboard = () => {
    document.title = "Dashboard | Ark Club";
    
    let navigate = useNavigate();
    
    const { user } = useSelector(state => state.user);

    const { response, isLoadingFetch, errorFetch } = useFetch(process.env.REACT_APP_BASE_API_URL+'/dashboard');

    return (
        <div className="container-fluid artist-details mb-4 py-2">
            <div className='mb-3'>
                <h6 className="title">Welcome {user.name}!</h6>

                <div className="row">
                    <div className="col-lg-3 col-sm-6 mb-4">
                        <div className="card border-left-dark shadow py-2">
                            <div className="card-body">
                                <div className="small font-weight-bold text-uppercase mb-1" style={{ color: '#5a5c69' }}>Total Users</div>
                                <div className="h2 mb-1 font-weight-bold text-gray-900">{ response?.membersTotal ?? '-' }</div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-3 col-sm-6 mb-4">
                        <div className="card border-left-info shadow py-2">
                            <div className="card-body">
                                <div className="small font-weight-bold text-uppercase mb-1" style={{ color: '#06897c' }}>With 11 connections</div>
                                <div className="h2 mb-1 font-weight-bold text-gray-900">{ response?.members11 ?? '-' }</div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-3 col-sm-6 mb-4">
                        <div className="card border-left-success shadow py-2">
                            <div className="card-body">
                                <div className="small font-weight-bold text-uppercase mb-1" style={{ color: '#058343' }}>With 12 connections</div>
                                <div className="h2 mb-1 font-weight-bold text-gray-900">{ response?.members12 ?? '-' }</div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-3 col-sm-6 mb-4">
                        <div className="card border-left-primary shadow py-2">
                            <div className="card-body">
                                <div className="small font-weight-bold text-uppercase mb-1" style={{ color: '#103dbf' }}>More than 12 connections</div>
                                <div className="h2 mb-1 font-weight-bold text-gray-900">{ response?.membersMore ?? '-' }</div>
                            </div>
                        </div>
                    </div>
                </div>

                <Card className="shadow mb-4">
                    <Card.Header className="py-3 d-flex">
                        <h5 className="h5 mb-0 text-gray1-800 font-weight-bold text-primary mr-3">Recently registered members</h5>
                        <h6 className="m-0 font-weight-bold text-primary ml-auto align-self-center text-right"><Link to="/members">View All</Link></h6>
                        <div className="clearfix"></div>
                    </Card.Header>
                    <Card.Body>
                        <Table responsive bordered hover>
                            <thead>
                                <tr>
                                    <th style={{minWidth: '180px', width: '180px'}}>Code</th>
                                    <th style={{minWidth: '180px'}}>Register Date</th>
                                    <th style={{minWidth: '180px'}}>Name</th>
                                    <th style={{minWidth: '180px'}}>Email</th>
                                    <th style={{minWidth: '180px'}}>Referrals</th>
                                    <th style={{width: '1px' }}>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                { response?.members?.map((item) => (
                                    <tr key={ item.id }>
                                        <td><code>{ item.referral_code }</code></td>
                                        <td>{ item.created_at_formatted }</td>
                                        <td>{ item.name }</td>
                                        <td><a href={`mailto:${item.email}`}>{ item.email }</a></td>
                                        <td>{ item.referees_total }</td>
                                        <td style={{padding: '0.3rem'}}>
                                            <Dropdown>
                                                <Dropdown.Toggle variant="secondary" className="btn-sm" id="dropdown-basic">Actions</Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <Dropdown.Item as={Link} to={`/members/detail/${item.id}`}>View</Dropdown.Item>
                                                    <Dropdown.Item as={Link} to={`/members/edit/${item.id}`}>Edit</Dropdown.Item>
                                                    {/* <Dropdown.Item onClick={() => setDeleteItem({id: item.id, url: 'members/'+item.id})} className="text-danger">Delete</Dropdown.Item> */}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Card.Body>
                </Card>
            </div>
        </div>
    );
}
 
export default Dashboard;