import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import AdminApp from "./Components/App";
import ScrollToTop from "./Components/ScrollToTop";
import { persistor, store } from './redux/store';
import { PersistGate } from 'redux-persist/integration/react';

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
    <React.StrictMode>
        <BrowserRouter>
            <Provider store={store}>
				<PersistGate loading={null} persistor={persistor}>
					<ScrollToTop />
					<AdminApp />
				</PersistGate>
            </Provider>
        </BrowserRouter>
    </React.StrictMode>
);
