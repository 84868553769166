import { useEffect, useState } from 'react';
import { Link, useSearchParams } from "react-router-dom";
import useFetch from '../useFetch';
import Paginate from '../Paginate';
import { Button, Card, Dropdown, Image, Table } from 'react-bootstrap';

const PendingMembersIndex = () => {
    document.title = "Pending Members | Ark Club";
    
    const [apiUrl, setApiUrl] = useState(process.env.REACT_APP_BASE_API_URL+'/pending-members');

    const [items, setItems] = useState([]);
    const [meta, setMeta] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    
    const { response, isLoading, error: fetchError } = useFetch(apiUrl);

    useEffect(() => {
        setItems(response?.members?.data ?? []);
        setMeta(response?.members?.meta ?? []);
    }, [response])

    return (
        <div className="container-fluid">
            <Card className="shadow mb-4">
                <Card.Header className="py-3 d-flex">
                    <h5 className="h5 mb-0 text-gray1-800 font-weight-bold text-primary mr-3">Pending Members</h5>
                    <div className="clearfix"></div>
                </Card.Header>
                <Card.Body>
                    { fetchError && <div className="alert-msg-danger"><h3 className="mb-0"><strong>{ fetchError }</strong></h3></div> }

                    { isLoading && <h5 className="text-center my-3">Loading Data</h5> }
                    
                    { !fetchError && !isLoading ? (
                        <>
                            { items?.length == 0 ? <h5 className="text-center text-danger my-3">No data found{currentPage > 0 ? ' on this page' : ''}</h5> : (
                                <Table responsive bordered hover>
                                    <thead>
                                        <tr>
                                            <th style={{minWidth: '180px', width: '180px'}}>Status</th>
                                            <th style={{minWidth: '180px'}}>Register Date</th>
                                            <th style={{minWidth: '180px', width: '180px'}}>Referral Code</th>
                                            <th style={{minWidth: '180px'}}>Name</th>
                                            <th style={{minWidth: '180px'}}>Email</th>
                                            <th style={{width: '1px' }}>View</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        { items?.map((item) => (
                                            <tr key={ item.id }>
                                                <td><p className='mb-0' style={{ fontWeight: '500', color: item.status_color }}>{item.status}</p></td>
                                                <td>{ item.created_at_formatted }</td>
                                                <td><code>{ item.referral_code }</code></td>
                                                <td>{ item.name }</td>
                                                <td><a href={`mailto:${item.email}`}>{ item.email }</a></td>
                                                <td style={{padding: '0.3rem'}}>
                                                    <Button as={Link} to={`detail/${item.id}`} variant="secondary" className="btn-sm">View</Button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            ) }

                            {meta?.last_page > 1 ? (
                                <Paginate
                                    url={apiUrl}
                                    setUrl={setApiUrl}
                                    currentPage={currentPage}
                                    setCurrentPage={setCurrentPage}
                                    pageCount={meta?.last_page}
                                />
                            ) : null}

                            <i className="d-block text-center">{meta?.total ? `(${meta?.total} records found)` : null}</i>
                        </>
                    ) : null}
                </Card.Body>
            </Card>
        </div>
    );
}
 
export default PendingMembersIndex;