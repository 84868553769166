import { useEffect, useState, useRef } from 'react';
import { Accordion, Form } from 'react-bootstrap';
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import useFetch from '../useFetch';
import Input from "./Forms/Input";
import Select from "./Forms/Select";
import Textarea from "./Forms/Textarea";

const AdminTrack = () => {
    document.title = "Track Member | Ark Club";
    
    let navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(false);
    const [validationErrors, setValidationErrors] = useState([]);

    const cardRef = useRef();

    const [code, setCode] = useState('');
    const [members, setMembers] = useState(null);
    
    const handleSubmit = (e) => {
        e.preventDefault();
        
        setIsLoading(true);
        setValidationErrors(false);
        setError(false);
        setMembers(null)
        
        let formData = new FormData();
        formData.append("code", code)
        
        fetch(process.env.REACT_APP_BASE_API_URL+'/track-admin', {
            method: 'POST',
            headers: { 
                "Accept": "application/json",
                "Authorization": "Bearer "+localStorage.getItem("token")
            },
            body: formData
        })
        .then(response => {
            if (!response.ok) {
                throw Error('There was some error while tracking, please try again.');
            }

            return response.json();
        })
        .then(data => {
            cardRef.current.scrollIntoView();
            setIsLoading(false);

            if (data?.status == 'success')
            {
                setMembers(data.members)
            }
            else if (data?.status == 'validation_error')
            {
                setValidationErrors(data.messages);
            }
            else if (data?.status == 'error' && data?.message)
            {
                setError(data.message);
            }
            else
            {
                setError('There was some error while tracking, please try again.');
            }
        })
        .catch(error => {
            cardRef.current.scrollIntoView();
            setError(error.message);
            setIsLoading(false);
        })
    }

    return ( 
        <div className="container-fluid">
            <div ref={cardRef} style={{ marginTop: '-80px', position: 'absolute' }}></div>
            <form onSubmit={handleSubmit}>
                <div className="card shadow mb-4">
                    <div className="card-header py-3 d-flex">
                        <h5 className="h5 mb-0 text-gray1-800 font-weight-bold text-primary mr-3">Track Member</h5>
                    </div>
                    <div className="card-body pb-2">                        
                        { error && <div className="alert-msg-danger"><h3 className="mb-0"><strong>{ error }</strong></h3></div> }

                        { validationErrors.length > 0 &&
                            <div className="alert-msg-danger">
                                <h3 className="mb-1"><strong>There was some error in submitted information, please try again.</strong></h3>
                                <ul className="mb-0" style={{fontSize: '15px'}}>
                                    { validationErrors.map((validationError, index) => (
                                        <li key={index}>{ validationError }</li>
                                    ))}
                                </ul>
                            </div>
                        }

                        <div className="row">
                            <div className="col-xl-4 col-md-6 mb-3">
                                <Input 
                                    label="Referral Code"
                                    value={code}
                                    setter={setCode}
                                    required={true}
                                    />
                            </div>
                        </div>

                        {members === null ? null : (
                            members.length == 0 ? (
                                <h5 className="text-danger mt-2 mb-3">Member has no referrals.</h5>
                            ) : (
                                <div className='mt-2' style={{ maxWidth: '350px' }}>
                                    <h5 className="text-success mb-3">Member has {members.length} referrals</h5>
                                    
                                    <div className='point-container'>
                                        { members?.map((item, index) => (
                                            <div key={item.id} className={`point-item d-flex ${index == (members.length - 1) ? 'end' : ''}`}>
                                                <div className='point-number mr-2'>{(members.length - index)}</div>
                                                <div className='ml-1' style={{ marginBottom: '35px' }}>
                                                    <p className='mb-1'><strong>{item.created_at_formatted}</strong></p>
                                                    <p className='mb-1'>Name: { item.name }</p>
                                                    <p className='mb-0'>Email: <a href={`mailto:${item.email}`}>{ item.email }</a></p>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )
                        )}
                    </div>
                    <div className="card-footer text-right">
                        <button 
                            type="submit" 
                            className="btn btn-secondary btn-submit w-100px"
                            disabled={isLoading}
                            >{ isLoading ? 'Tracking...' : 'Track' }</button>
                    </div>
                </div>
            </form>
        </div>
    );
}
 
export default AdminTrack;