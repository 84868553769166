import { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Button, Nav, Navbar, NavDropdown } from "react-bootstrap";
import { Icon } from "@iconify/react";
import { store } from "../redux/store";
import { removeUser } from "../redux/userSlice";

const Header = ({sidebarToggled, setSidebarToggled}) => {
    let navigate = useNavigate();

    const currentPath = useLocation().pathname.split('/')[1];
    
    const handleLogout = (e) => {
        e.preventDefault()
        
        fetch(process.env.REACT_APP_BASE_API_URL+'/logout', {
            method: 'POST',
            headers: { 
                "Accept": "application/json",
                "Authorization": "Bearer "+localStorage.getItem("token")
            },
        })
        
        store.dispatch(removeUser());
        navigate('/');
    }

    return ( 
        <nav className="navbar navbar-expand navbar-light topbar static-top shadow">
            <Link id="sidebarToggleTop" className="rounded-circle mr-3 d-sm-none" onClick={() => setSidebarToggled(!sidebarToggled)}>
                <Icon icon="material-symbols:menu-rounded" width={20} height={20} />
            </Link>
            <Navbar.Brand>
                <Link to={"/"}>
                    <img src="/logo.svg"/>
                </Link>
            </Navbar.Brand>
            <ul className={`navbar-nav d-none d-sm-block ${currentPath == 'dashboard' ? 'active' : ''}`}>
                <Nav.Link as={Link} to={"/dashboard"}>Dashboard</Nav.Link>
            </ul>
            <ul className={`navbar-nav d-none d-sm-block ${currentPath == 'members' ? 'active' : ''}`}>
                <Nav.Link as={Link} to={"/members"}>Members</Nav.Link>
            </ul>
            <ul className={`navbar-nav d-none d-sm-block ${currentPath == 'pending-members' ? 'active' : ''}`}>
                <Nav.Link as={Link} to={"/pending-members"}>Pending Members</Nav.Link>
            </ul>
            <ul className={`navbar-nav d-none d-sm-block ${currentPath == 'payments' ? 'active' : ''}`}>
                <Nav.Link as={Link} to={"/payments"}>Payments</Nav.Link>
            </ul>
            <ul className={`navbar-nav d-none d-sm-block ${currentPath == 'track-admin' ? 'active' : ''}`}>
                <Nav.Link as={Link} to={"/track-admin"}>Track</Nav.Link>
            </ul>
            {/* <ul className={`navbar-nav d-none d-sm-block ${currentPath == 'charities' ? 'active' : ''}`}>
                <Nav.Link as={Link} to={"/charities"}>Charities</Nav.Link>
            </ul> */}
            <ul className={`navbar-nav d-none d-sm-block ${currentPath == 'settings' ? 'active' : ''}`}>
                <Nav.Link as={Link} to={"/settings"}>Settings</Nav.Link>
            </ul>
            <ul className="navbar-nav d-none d-sm-block ml-auto">
                <Nav.Link as={Link} onClick={handleLogout} className="text-danger" style={{ fontWeight: 'normal' }}>Logout</Nav.Link>
            </ul>
        </nav>
     );
}
 
export default Header;