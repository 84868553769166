import { useEffect, useState, useRef } from 'react';
import { Button, Form } from 'react-bootstrap';
import { Link, useNavigate } from "react-router-dom";

const Unauthorized = () => {
    document.title = "Unauthorized | Ark Club";
    
    return (
        <div className="container-fluid py-2">
            <div className="text-center">
                <h3 className='text-danger pt-3 mb-0'>You are not authorized to view this page.</h3>
                <Button as={Link} to="/" className="mt-3" variant='info'>Login to access</Button>
            </div>
        </div>
    );
}
 
export default Unauthorized;