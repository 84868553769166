import { useEffect, useState, useRef } from 'react';
import { Accordion, Form } from 'react-bootstrap';
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import useFetch from '../useFetch';
import Input from "./Forms/Input";
import Select from "./Forms/Select";
import Textarea from "./Forms/Textarea";
import { Button, Card, Dropdown, Image, Table } from 'react-bootstrap';

const MembersDetail = () => {    
    document.title = "Member Details | Ark Club";
    
    const { id } = useParams();
    let navigate = useNavigate();

    const { response, isLoading, error: fetchError } = useFetch(process.env.REACT_APP_BASE_API_URL+'/members/'+id);

    const [isLoadingTransfer, setIsLoadingTransfer] = useState(false);
    const [successTransfer, setSuccessTransfer] = useState(false);
    const [errorTransfer, setErrorTransfer] = useState(false);

    const handleTransfer = (e) => {
        e.preventDefault();
        
        setIsLoadingTransfer(true);
        setErrorTransfer(false);
        setSuccessTransfer(false);

        fetch(process.env.REACT_APP_BASE_API_URL+'/manual-transfer/'+id, {
            method: 'POST',
            headers: { 
                "Accept": "application/json",
                "Authorization": "Bearer "+localStorage.getItem("token")
            },
        })
        .then(response => {
            if (!response.ok) {
                throw Error('There was some error while transfering, please try again.');
            }

            return response.json();
        })
        .then(data => {
            setIsLoadingTransfer(false);

            if (data?.status == 'success')
            {
                setSuccessTransfer(data.message);
            }
            else if (data?.status == 'error' && data?.message)
            {
                setErrorTransfer(data.message);
            }
            else
            {
                setErrorTransfer('There was some error while transfering, please try again.');
            }
        })
        .catch(error => {
            setErrorTransfer(error.message);
            setIsLoadingTransfer(false);
        })
    }

    const handleResendEmail = (e) => {
        e.preventDefault();
        
        setIsLoadingTransfer(true);
        setErrorTransfer(false);
        setSuccessTransfer(false);

        fetch(process.env.REACT_APP_BASE_API_URL+'/resend-bank-email/'+id, {
            method: 'POST',
            headers: { 
                "Accept": "application/json",
                "Authorization": "Bearer "+localStorage.getItem("token")
            },
        })
        .then(response => {
            if (!response.ok) {
                throw Error('There was some error while resending email, please try again.');
            }

            return response.json();
        })
        .then(data => {
            setIsLoadingTransfer(false);

            if (data?.status == 'success')
            {
                setSuccessTransfer(data.message);
            }
            else if (data?.status == 'error' && data?.message)
            {
                setErrorTransfer(data.message);
            }
            else
            {
                setErrorTransfer('There was some error while resending email, please try again.');
            }
        })
        .catch(error => {
            setErrorTransfer(error.message);
            setIsLoadingTransfer(false);
        })
    }
    
    return ( 
        <div className="container-fluid">
            <div className="card shadow mb-4">
                <div className="card-header py-3 d-flex">
                    <h5 className="h5 mb-0 text-gray1-800 font-weight-bold text-primary mr-3">Member Details</h5>
                    <h6 className="m-0 font-weight-bold text-primary ml-auto align-self-center text-right"><a href="#" onClick={() => navigate(-1)}>Back</a></h6>
                    <div className="clearfix"></div>
                </div>
                <div className="card-body pb-2">
                    { fetchError && <div className="alert-msg-danger"><h3 className="mb-0"><strong>{ fetchError }</strong></h3></div> }

                    { isLoading && <h5 className="text-center my-3">Loading Data</h5> }

                    { successTransfer && <div className="alert-msg-success"><h3 className="mb-0"><strong>{ successTransfer }</strong></h3></div> }
                        
                    { errorTransfer && <div className="alert-msg-danger"><h3 className="mb-0"><strong>{ errorTransfer }</strong></h3></div> }

                    { !fetchError && !isLoading ? (
                        <>
                            <button className='btn btn-warning btn-sm mb-3 mr-2' onClick={handleTransfer} disabled={isLoadingTransfer}>{isLoadingTransfer ? 'Please wait...' : 'Transfer commission manually'}</button>
                            <button className='btn btn-info btn-sm mb-3 mr-2' onClick={handleResendEmail} disabled={isLoadingTransfer}>{isLoadingTransfer ? 'Please wait...' : 'Resend bank details email'}</button>
                            <div className="row">
                                <div className="col-xl-4 col-md-6 mb-3">
                                    <p className='mb-1'>Register Date:</p>
                                    <p className='mb-0' style={{ fontWeight: '500' }}>{response?.data?.created_at_formatted}</p>
                                </div>
                                <div className="col-xl-4 col-md-6 mb-3">
                                    <p className='mb-1'>Code:</p>
                                    <p className='mb-0' style={{ fontWeight: '500' }}>{response?.data?.referral_code}</p>
                                </div>
                                <div className="col-xl-4 col-md-6 mb-3">
                                    <p className='mb-1'>Name:</p>
                                    <p className='mb-0' style={{ fontWeight: '500' }}>{response?.data?.name}</p>
                                </div>
                                <div className="col-xl-4 col-md-6 mb-3">
                                    <p className='mb-1'>Email:</p>
                                    <p className='mb-0' style={{ fontWeight: '500' }}><a href={`mailto:${response?.data?.email}`}>{response?.data?.email}</a></p>
                                </div>
                                <div className="col-xl-4 col-md-6 mb-3">
                                    <p className='mb-1'>Address:</p>
                                    <p className='mb-0' style={{ fontWeight: '500' }}>{response?.data?.address}</p>
                                </div>
                                {response?.data?.reward_status ? (
                                    <div className="col-xl-4 col-md-6 mb-3">
                                        <p className='mb-1'>Reward Status:</p>
                                        <p className='mb-0' style={{ fontWeight: '500', color: response?.data?.reward_status_color }}>{response?.data?.reward_status}</p>
                                    </div>
                                ) : null}
                                <div className="col-xl-4 col-md-6 mb-3">
                                    <p className='mb-1'>Stripe Account ID:</p>
                                    <p className='mb-0' style={{ fontWeight: '500' }}>{response?.data?.stripe_account}</p>
                                </div>
                                {response?.data?.payment?.id ? (
                                    <>
                                        <div className="col-xl-4 col-md-6 mb-3" style={{background: '#d4ffe1'}}>
                                            <p className='mb-1'>Payment ID:</p>
                                            <p className='mb-0' style={{ fontWeight: '500' }}>{response?.data?.payment?.destination_payment}</p>
                                        </div>
                                        <div className="col-xl-4 col-md-6 mb-3" style={{background: '#d4ffe1'}}>
                                            <p className='mb-1'>Transfer ID:</p>
                                            <p className='mb-0' style={{ fontWeight: '500' }}>{response?.data?.payment?.transfer}</p>
                                        </div>
                                        <div className="col-xl-4 col-md-6 mb-3" style={{background: '#d4ffe1'}}>
                                            <p className='mb-1'>Amount:</p>
                                            <p className='mb-0' style={{ fontWeight: '500' }}>{response?.data?.payment?.amount} {response?.data?.payment?.currency}</p>
                                        </div>
                                        <div className="col-xl-4 col-md-6 mb-3" style={{background: '#d4ffe1'}}>
                                            <p className='mb-1'>Transfer Date:</p>
                                            <p className='mb-0' style={{ fontWeight: '500' }}>{response?.data?.payment?.stripe_created_at}</p>
                                        </div>
                                    </>
                                ) : null}
                                {/* <div className="col-xl-4 col-md-6 mb-3">
                                    <p className='mb-1'>Charity:</p>
                                    <p className='mb-0' style={{ fontWeight: '500' }}>{response?.data?.charity?.title}</p>
                                </div> */}
                                <div className="col-xl-4 col-md-6 mb-3">
                                    <p className='mb-1'>Total referrals:</p>
                                    <p className='mb-0' style={{ fontWeight: '500' }}>{response?.data?.referees_total}</p>
                                </div>
                                {response?.data?.referer ? (
                                    <>
                                        <div className="col-xl-4 col-md-6 mb-3">
                                            <p className='mb-1'>Referer Code:</p>
                                            <p className='mb-0' style={{ fontWeight: '500' }}>{response?.data?.referer?.referral_code}</p>
                                        </div>
                                        <div className="col-xl-4 col-md-6 mb-3">
                                            <p className='mb-1'>Referer Name:</p>
                                            <p className='mb-0' style={{ fontWeight: '500' }}>{response?.data?.referer?.name}</p>
                                        </div>
                                        <div className="col-xl-4 col-md-6 mb-3">
                                            <p className='mb-1'>Referer Email:</p>
                                            <p className='mb-0' style={{ fontWeight: '500' }}><a href={`mailto:${response?.data?.referer?.email}`}>{response?.data?.referer?.email}</a></p>
                                        </div>
                                    </>
                                ) : null}
                            </div>

                            { response?.data?.referees_total == 0 ? (
                                <h5 className="text-center text-danger mb-3">This user has no referrals.</h5>
                            ) : (
                                <>
                                    <h5 className="text-success mb-1">Referrals:</h5>
                                    <Table responsive bordered hover style={{ minHeight: 'auto' }}>
                                        <thead>
                                            <tr>
                                                <th style={{minWidth: '180px', width: '180px'}}>Code</th>
                                                <th style={{minWidth: '180px'}}>Register Date</th>
                                                <th style={{minWidth: '180px'}}>Name</th>
                                                <th style={{minWidth: '180px'}}>Email</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            { response?.data?.referees?.map((item) => (
                                                <tr key={ item.id }>
                                                    <td><code>{ item.referral_code }</code></td>
                                                    <td>{ item.created_at_formatted }</td>
                                                    <td>{ item.name }</td>
                                                    <td><a href={`mailto:${item.email}`}>{ item.email }</a></td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </>
                            ) }
                        </>
                    ) : null}
                </div>
            </div>
        </div>
    );
}
 
export default MembersDetail;