import { useEffect, useState, useRef } from 'react';
import { Accordion, Form } from 'react-bootstrap';
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import useFetch from '../useFetch';
import Input from "./Forms/Input";
import Select from "./Forms/Select";
import Textarea from "./Forms/Textarea";

const MembersCreate = () => {
    document.title = "Add Member | Ark Club";
    
    let navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [validationErrors, setValidationErrors] = useState([]);

    const cardRef = useRef();

    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [address, setAddress] = useState('')
    const [referrer, setReferrer] = useState('')
    //const [charity, setCharity] = useState(null);

    //const { response: charities } = useFetch(process.env.REACT_APP_BASE_API_URL+'/charities');

    const handleSubmit = (e) => {
        e.preventDefault();
        
        setIsLoading(true);
        setValidationErrors(false);
        setError(false);
        setSuccess(false);

        let formData = new FormData();
        formData.append("name", name)
        formData.append("email", email)
        formData.append("address", address)
        if (referrer) formData.append("referrer", referrer)
        //formData.append("charity", charity)
        
        fetch(process.env.REACT_APP_BASE_API_URL+'/members', {
            method: 'POST',
            headers: { 
                "Accept": "application/json",
                "Authorization": "Bearer "+localStorage.getItem("token")
            },
            body: formData
        })
        .then(response => {
            if (!response.ok) {
                throw Error('There was some error while adding member, please try again.');
            }

            return response.json();
        })
        .then(data => {
            cardRef.current.scrollIntoView();
            setIsLoading(false);

            if (data?.status == 'success')
            {
                setSuccess(data.message);

                setName('');
                setEmail('');
                setAddress('')
                setReferrer('');
                //setCharity(null)
            }
            else if (data?.status == 'validation_error')
            {
                setValidationErrors(data.messages);
            }
            else if (data?.status == 'error' && data?.message)
            {
                setError(data.message);
            }
            else
            {
                setError('There was some error while adding member, please try again.');
            }
        })
        .catch(error => {
            cardRef.current.scrollIntoView();
            setError(error.message);
            setIsLoading(false);
        })
    }

    return ( 
        <div className="container-fluid">
            <div ref={cardRef} style={{ marginTop: '-80px', position: 'absolute' }}></div>
            <form onSubmit={handleSubmit}>
                <div className="card shadow mb-4">
                    <div className="card-header py-3 d-flex">
                        <h5 className="h5 mb-0 text-gray1-800 font-weight-bold text-primary mr-3">Add Member</h5>
                        <h6 className="m-0 font-weight-bold text-primary ml-auto align-self-center text-right"><a href="#" onClick={() => navigate(-1)}>Back</a></h6>
                        <div className="clearfix"></div>
                    </div>
                    <div className="card-body pb-2">
                        { success && <div className="alert-msg-success"><h3 className="mb-0"><strong>{ success }</strong></h3></div> }
                        
                        { error && <div className="alert-msg-danger"><h3 className="mb-0"><strong>{ error }</strong></h3></div> }

                        { validationErrors.length > 0 &&
                            <div className="alert-msg-danger">
                                <h3 className="mb-1"><strong>There was some error in submitted information, please try again.</strong></h3>
                                <ul className="mb-0" style={{fontSize: '15px'}}>
                                    { validationErrors.map((validationError, index) => (
                                        <li key={index}>{ validationError }</li>
                                    ))}
                                </ul>
                            </div>
                        }

                        <div className="row">
                            <div className="col-xl-4 col-md-6 mb-3">
                                <Input 
                                    label="Name"
                                    value={name}
                                    setter={setName}
                                    required={true}
                                    />
                            </div>
                            <div className="col-xl-4 col-md-6 mb-3">
                                <Input 
                                    type='email'
                                    label="Email"
                                    value={email}
                                    setter={setEmail}
                                    required={true}
                                    />
                            </div>
                            <div className="col-xl-4 col-md-6 mb-3">
                                <Input 
                                    label="Referrer ID"
                                    value={referrer}
                                    setter={setReferrer}
                                    style={{ fontFamily: 'monospace', textTransform: 'uppercase' }}
                                    />
                            </div>
                            <div className="col-xl-4 col-md-6 mb-3">
                                <Textarea
                                    label="Address"
                                    value={address}
                                    setter={setAddress}
                                    />
                            </div>
                            {/* <div className="col-xl-8 col-md-12 mb-3">
                                <label className="mb-1">Charity</label>
                                <div className='d-flex flex-wrap'>
                                    {charities?.charities?.data?.map(value => (
                                        <p onClick={() => setCharity(value.id)} className={`badge-checkbox ${charity == value.id ? 'primary' : 'light'}`}>{value.title}</p>
                                    ))}
                                </div>
                            </div> */}
                        </div>
                    </div>
                    <div className="card-footer text-right">
                        <button 
                            type="submit" 
                            className="btn btn-secondary btn-submit w-100px"
                            disabled={isLoading}
                            >{ isLoading ? 'Loading...' : 'Submit' }</button>
                    </div>
                </div>
            </form>
        </div>
    );
}
 
export default MembersCreate;