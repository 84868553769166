import { useEffect, useState, useRef } from 'react';
import { Accordion, Form } from 'react-bootstrap';
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import useFetch from '../useFetch';
import Input from "./Forms/Input";
import Select from "./Forms/Select";
import Textarea from "./Forms/Textarea";
import { Button, Card, Dropdown, Image, Table } from 'react-bootstrap';

const PendingMembersDetail = () => {    
    document.title = "Pending Member Details | Ark Club";
    
    const { id } = useParams();
    let navigate = useNavigate();

    const { response, isLoading, error: fetchError } = useFetch(process.env.REACT_APP_BASE_API_URL+'/pending-members/'+id);

    const [isLoadingCreate, setIsLoadingCreate] = useState(false);
    const [successCreate, setSuccessCreate] = useState(false);
    const [errorCreate, setErrorCreate] = useState(false);

    const handleCreate = (e) => {
        e.preventDefault();
        
        if (!window.confirm('Are you sure to create account for this user?'))
        {
            return;
        }

        setIsLoadingCreate(true);
        setErrorCreate(false);
        setSuccessCreate(false);

        fetch(process.env.REACT_APP_BASE_API_URL+'/create-account/'+id, {
            method: 'POST',
            headers: { 
                "Accept": "application/json",
                "Authorization": "Bearer "+localStorage.getItem("token")
            },
        })
        .then(response => {
            if (!response.ok) {
                throw Error('There was some error while creating account, please try again.');
            }

            return response.json();
        })
        .then(data => {
            setIsLoadingCreate(false);

            if (data?.status == 'success')
            {
                setSuccessCreate(data.message);
            }
            else if (data?.status == 'error' && data?.message)
            {
                setErrorCreate(data.message);
            }
            else
            {
                setErrorCreate('There was some error while creating account, please try again.');
            }
        })
        .catch(error => {
            setErrorCreate(error.message);
            setIsLoadingCreate(false);
        })
    }

    return ( 
        <div className="container-fluid">
            <div className="card shadow mb-4">
                <div className="card-header py-3 d-flex">
                    <h5 className="h5 mb-0 text-gray1-800 font-weight-bold text-primary mr-3">Pending Member Details</h5>
                    <h6 className="m-0 font-weight-bold text-primary ml-auto align-self-center text-right"><a href="#" onClick={() => navigate(-1)}>Back</a></h6>
                    <div className="clearfix"></div>
                </div>
                <div className="card-body pb-2">
                    { fetchError && <div className="alert-msg-danger"><h3 className="mb-0"><strong>{ fetchError }</strong></h3></div> }

                    { isLoading && <h5 className="text-center my-3">Loading Data</h5> }

                    { successCreate && <div className="alert-msg-success"><h3 className="mb-0"><strong>{ successCreate }</strong></h3></div> }
                        
                    { errorCreate && <div className="alert-msg-danger"><h3 className="mb-0"><strong>{ errorCreate }</strong></h3></div> }

                    { !fetchError && !isLoading ? (
                        <>
                            {!response?.data?.is_created && !successCreate ? (
                                <button className='btn btn-success btn-sm mb-3 mr-2' onClick={handleCreate} disabled={isLoadingCreate}>{isLoadingCreate ? 'Please wait...' : 'Create account'}</button>
                            ) : null}
                            <div className="row">
                                <div className="col-xl-4 col-md-6 mb-3">
                                    <p className='mb-1'>Register Date:</p>
                                    <p className='mb-0' style={{ fontWeight: '500' }}>{response?.data?.created_at_formatted}</p>
                                </div>
                                <div className="col-xl-4 col-md-6 mb-3">
                                    <p className='mb-1'>Referral Code:</p>
                                    <p className='mb-0' style={{ fontWeight: '500' }}>{response?.data?.referral_code}</p>
                                </div>
                                <div className="col-xl-4 col-md-6 mb-3">
                                    <p className='mb-1'>Name:</p>
                                    <p className='mb-0' style={{ fontWeight: '500' }}>{response?.data?.name}</p>
                                </div>
                                <div className="col-xl-4 col-md-6 mb-3">
                                    <p className='mb-1'>Email:</p>
                                    <p className='mb-0' style={{ fontWeight: '500' }}><a href={`mailto:${response?.data?.email}`}>{response?.data?.email}</a></p>
                                </div>
                                <div className="col-xl-4 col-md-6 mb-3">
                                    <p className='mb-1'>Address:</p>
                                    <p className='mb-0' style={{ fontWeight: '500' }}>{response?.data?.address}</p>
                                </div>
                                <div className="col-xl-4 col-md-6 mb-3">
                                    <p className='mb-1'>Status:</p>
                                    <p className='mb-0' style={{ fontWeight: '500', color: response?.data?.status_color }}>{response?.data?.status}</p>
                                </div>
                                <div className="col-xl-4 col-md-6 mb-3">
                                    <p className='mb-1'>Stripe Payment ID:</p>
                                    <p className='mb-0' style={{ fontWeight: '500' }}>{response?.data?.payment_id}</p>
                                </div>
                            </div>
                        </>
                    ) : null}
                </div>
            </div>
        </div>
    );
}
 
export default PendingMembersDetail;