import { useEffect, useState, useRef } from 'react';
import { Accordion, Form } from 'react-bootstrap';
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import useFetch from '../useFetch';
import Input from "./Forms/Input";
import Select from "./Forms/Select";
import Textarea from "./Forms/Textarea";

const Settings = () => {
    document.title = "Settings | Ark Club";

    const [isLoadingPassword, setIsLoadingPassword] = useState(false);
    const [successPassword, setSuccessPassword] = useState(false);
    const [errorPassword, setErrorPassword] = useState(false);
    const [validationErrorsPassword, setValidationErrorsPassword] = useState([]);

    const cardRefPassword = useRef();
    
    const [currentPassword, setCurrentPassword] = useState('');
    const [password, setPassword] = useState('');
    const [passwordConfirm, setPasswordConfirm] = useState('');

    
    
    const [isLoadingEmail, setIsLoadingEmail] = useState(false);
    const [successEmail, setSuccessEmail] = useState(false);
    const [errorEmail, setErrorEmail] = useState(false);
    const [validationErrorsEmail, setValidationErrorsEmail] = useState([]);

    const cardRefEmail = useRef();
    
    const [email, setEmail] = useState('');
    
    
    
    const [isLoadingCommission, setIsLoadingCommission] = useState(false);
    const [successCommission, setSuccessCommission] = useState(false);
    const [errorCommission, setErrorCommission] = useState(false);
    const [validationErrorsCommission, setValidationErrorsCommission] = useState([]);

    const cardRefCommission = useRef();
    
    const [commission, setCommission] = useState('');
    
    
    
    
    const [isLoadingReset, setIsLoadingReset] = useState(false);
    const [successReset, setSuccessReset] = useState(false);
    const [errorReset, setErrorReset] = useState(false);
    
    const { response } = useFetch(process.env.REACT_APP_BASE_API_URL+'/settings');
    useEffect(() => {
        if (response?.status == 'success')
        {
            if (response.admin_email) setEmail(response.admin_email);
            if (response.commission_amount) setCommission(response.commission_amount);
        }
    }, [response])

    const handlePassword = (e) => {
        e.preventDefault();

        setIsLoadingPassword(true);
        setValidationErrorsPassword(false);
        setErrorPassword(false);
        setSuccessPassword(false);

        let formData = new FormData();
        formData.append("_method", "PUT");
        formData.append("current_password", currentPassword)
        formData.append("password", password)
        formData.append("password_confirmation", passwordConfirm)

        fetch(process.env.REACT_APP_BASE_API_URL+'/update-password', {
            method: 'POST',
            headers: { 
                "Accept": "application/json",
                "Authorization": "Bearer "+localStorage.getItem("token")
            },
            body: formData
        })
        .then(response => {
            if (!response.ok) {
                throw Error('There was some error while updating password, please try again.');
            }

            return response.json();
        })
        .then(data => {
            cardRefPassword.current.scrollIntoView();
            setIsLoadingPassword(false);

            if (data?.status == 'success')
            {
                setSuccessPassword(data.message);

                setCurrentPassword('');
                setPassword('');
                setPasswordConfirm('');
            }
            else if (data?.status == 'validation_error')
            {
                setValidationErrorsPassword(data.messages);
            }
            else if (data?.status == 'error' && data?.message)
            {
                setErrorPassword(data.message);
            }
            else
            {
                setErrorPassword('There was some error while updating password, please try again.');
            }
        })
        .catch(error => {
            cardRefPassword.current.scrollIntoView();
            setErrorPassword(error.message);
            setIsLoadingPassword(false);
        })
    }

    const handleEmail= (e) => {
        e.preventDefault();

        setIsLoadingEmail(true);
        setValidationErrorsEmail(false);
        setErrorEmail(false);
        setSuccessEmail(false);

        let formData = new FormData();
        formData.append("_method", "PUT");
        formData.append("email", email)

        fetch(process.env.REACT_APP_BASE_API_URL+'/update-email', {
            method: 'POST',
            headers: { 
                "Accept": "application/json",
                "Authorization": "Bearer "+localStorage.getItem("token")
            },
            body: formData
        })
        .then(response => {
            if (!response.ok) {
                throw Error('There was some error while updating email, please try again.');
            }

            return response.json();
        })
        .then(data => {
            cardRefEmail.current.scrollIntoView();
            setIsLoadingEmail(false);

            if (data?.status == 'success')
            {
                setSuccessEmail(data.message);
            }
            else if (data?.status == 'validation_error')
            {
                setValidationErrorsEmail(data.messages);
            }
            else if (data?.status == 'error' && data?.message)
            {
                setErrorEmail(data.message);
            }
            else
            {
                setErrorEmail('There was some error while updating email, please try again.');
            }
        })
        .catch(error => {
            cardRefEmail.current.scrollIntoView();
            setErrorEmail(error.message);
            setIsLoadingEmail(false);
        })
    }

    const handleCommission = (e) => {
        e.preventDefault();

        setIsLoadingCommission(true);
        setValidationErrorsCommission(false);
        setErrorCommission(false);
        setSuccessCommission(false);

        let formData = new FormData();
        formData.append("_method", "PUT");
        formData.append("amount", commission)

        fetch(process.env.REACT_APP_BASE_API_URL+'/update-commission', {
            method: 'POST',
            headers: { 
                "Accept": "application/json",
                "Authorization": "Bearer "+localStorage.getItem("token")
            },
            body: formData
        })
        .then(response => {
            if (!response.ok) {
                throw Error('There was some error while updating commission, please try again.');
            }

            return response.json();
        })
        .then(data => {
            cardRefCommission.current.scrollIntoView();
            setIsLoadingCommission(false);

            if (data?.status == 'success')
            {
                setSuccessCommission(data.message);
            }
            else if (data?.status == 'validation_error')
            {
                setValidationErrorsCommission(data.messages);
            }
            else if (data?.status == 'error' && data?.message)
            {
                setErrorCommission(data.message);
            }
            else
            {
                setErrorCommission('There was some error while updating commission, please try again.');
            }
        })
        .catch(error => {
            cardRefCommission.current.scrollIntoView();
            setErrorCommission(error.message);
            setIsLoadingCommission(false);
        })
    }

    const handleReset= (e) => {
        e.preventDefault();

        if (!window.confirm('Are you sure to reset the database?'))
        {
            return;
        }

        setIsLoadingReset(true);
        setErrorReset(false);
        setSuccessReset(false);

        fetch(process.env.REACT_APP_BASE_API_URL+'/reset-database', {
            method: 'POST',
            headers: { 
                "Accept": "application/json",
                "Authorization": "Bearer "+localStorage.getItem("token")
            },
        })
        .then(response => {
            if (!response.ok) {
                throw Error('There was some error while resetting database, please try again.');
            }

            return response.json();
        })
        .then(data => {
            setIsLoadingReset(false);

            if (data?.status == 'success')
            {
                setSuccessReset(data.message);
            }
            else if (data?.status == 'error' && data?.message)
            {
                setErrorReset(data.message);
            }
            else
            {
                setErrorReset('There was some error while resetting database, please try again.');
            }
        })
        .catch(error => {
            setErrorReset(error.message);
            setIsLoadingReset(false);
        })
    }

    return (
        <div className="container-fluid artist-details mb-4 py-2">
            <div className='mb-3'>
                <h6 className="title mb-3">Settings</h6>

                <div ref={cardRefPassword} style={{ marginTop: '-80px', position: 'absolute' }}></div>
                <form onSubmit={handlePassword}>
                    <div className="card shadow mb-4">
                        <div className="card-header py-3" style={{ minHeight: 'initial' }}>
                            <h6 className="h6 mb-0 text-gray1-800 font-weight-bold text-primary">Update Password</h6>
                        </div>
                        <div className="card-body pb-2">
                            { successPassword && <div className="alert-msg-success"><h3 className="mb-0"><strong>{ successPassword }</strong></h3></div> }
                            
                            { errorPassword && <div className="alert-msg-danger"><h3 className="mb-0"><strong>{ errorPassword }</strong></h3></div> }

                            { validationErrorsPassword.length > 0 &&
                                <div className="alert-msg-danger">
                                    <h3 className="mb-1"><strong>There was some error in submitted information, please try again.</strong></h3>
                                    <ul className="mb-0" style={{fontSize: '15px'}}>
                                        { validationErrorsPassword.map((validationError, index) => (
                                            <li key={index}>{ validationError }</li>
                                        ))}
                                    </ul>
                                </div>
                            }

                            <div className="row">
                                <div className="col-xl-4 col-md-6 mb-3">
                                    <Input 
                                        type='password'
                                        label="Current password"
                                        value={currentPassword}
                                        setter={setCurrentPassword}
                                        required={true}
                                        />
                                </div>
                                <div className="col-xl-4 col-md-6 mb-3">
                                    <Input 
                                        type='password'
                                        label="New password"
                                        value={password}
                                        setter={setPassword}
                                        required={true}
                                        />
                                </div>
                                <div className="col-xl-4 col-md-6 mb-3">
                                    <Input 
                                        type='password'
                                        label="Confirm new password"
                                        value={passwordConfirm}
                                        setter={setPasswordConfirm}
                                        required={true}
                                        />
                                </div>
                            </div>
                        </div>
                        <div className="card-footer text-right">
                            <button 
                                type="submit" 
                                className="btn btn-secondary btn-submit w-100px"
                                disabled={isLoadingPassword}
                                >{ isLoadingPassword ? 'Loading...' : 'Submit' }</button>
                        </div>
                    </div>
                </form>

                <div className='row'>
                    <div className='col-md-6'>
                        <div ref={cardRefEmail} style={{ marginTop: '-80px', position: 'absolute' }}></div>
                        <form onSubmit={handleEmail}>
                            <div className="card shadow mb-4">
                                <div className="card-header py-3" style={{ minHeight: 'initial' }}>
                                    <h6 className="h6 mb-0 text-gray1-800 font-weight-bold text-primary">Update Email</h6>
                                </div>
                                <div className="card-body pb-2">
                                    { successEmail && <div className="alert-msg-success"><h3 className="mb-0"><strong>{ successEmail }</strong></h3></div> }
                                    
                                    { errorEmail && <div className="alert-msg-danger"><h3 className="mb-0"><strong>{ errorEmail }</strong></h3></div> }

                                    { validationErrorsEmail.length > 0 &&
                                        <div className="alert-msg-danger">
                                            <h3 className="mb-1"><strong>There was some error in submitted information, please try again.</strong></h3>
                                            <ul className="mb-0" style={{fontSize: '15px'}}>
                                                { validationErrorsEmail.map((validationError, index) => (
                                                    <li key={index}>{ validationError }</li>
                                                ))}
                                            </ul>
                                        </div>
                                    }

                                    <div className="mb-3">
                                        <Input 
                                            type='email'
                                            label="Admin email"
                                            value={email}
                                            setter={setEmail}
                                            required={true}
                                            />
                                    </div>
                                </div>
                                <div className="card-footer text-right">
                                    <button 
                                        type="submit" 
                                        className="btn btn-secondary btn-submit w-100px"
                                        disabled={isLoadingEmail}
                                        >{ isLoadingEmail ? 'Loading...' : 'Submit' }</button>
                                </div>
                            </div>
                        </form>
                    </div>

                    <div className='col-md-6'>
                        <div ref={cardRefCommission} style={{ marginTop: '-80px', position: 'absolute' }}></div>
                        <form onSubmit={handleCommission}>
                            <div className="card shadow mb-4">
                                <div className="card-header py-3" style={{ minHeight: 'initial' }}>
                                    <h6 className="h6 mb-0 text-gray1-800 font-weight-bold text-primary">Update Commission</h6>
                                </div>
                                <div className="card-body pb-2">
                                    { successCommission && <div className="alert-msg-success"><h3 className="mb-0"><strong>{ successCommission }</strong></h3></div> }
                                    
                                    { errorCommission && <div className="alert-msg-danger"><h3 className="mb-0"><strong>{ errorCommission }</strong></h3></div> }

                                    { validationErrorsCommission.length > 0 &&
                                        <div className="alert-msg-danger">
                                            <h3 className="mb-1"><strong>There was some error in submitted information, please try again.</strong></h3>
                                            <ul className="mb-0" style={{fontSize: '15px'}}>
                                                { validationErrorsCommission.map((validationError, index) => (
                                                    <li key={index}>{ validationError }</li>
                                                ))}
                                            </ul>
                                        </div>
                                    }

                                    <div className="mb-3">
                                        <Input 
                                            label="Commission amount (1234 = 12.34)"
                                            value={commission}
                                            setter={setCommission}
                                            required={true}
                                            />
                                    </div>
                                </div>
                                <div className="card-footer text-right">
                                    <button 
                                        type="submit" 
                                        className="btn btn-secondary btn-submit w-100px"
                                        disabled={isLoadingCommission}
                                        >{ isLoadingCommission ? 'Loading...' : 'Submit' }</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                <form onSubmit={handleReset}>
                    <div className="card shadow mb-4">
                        <div className="card-header py-3" style={{ minHeight: 'initial' }}>
                            <h6 className="h6 mb-0 text-gray1-800 font-weight-bold text-primary">Reset database</h6>
                        </div>
                        <div className="card-body">
                            { successReset && <div className="alert-msg-success"><h3 className="mb-0"><strong>{ successReset }</strong></h3></div> }
                            
                            { errorReset && <div className="alert-msg-danger"><h3 className="mb-0"><strong>{ errorReset }</strong></h3></div> }
                            
                            <button 
                                type="submit" 
                                className="btn btn-danger btn-submit w-100px"
                                disabled={isLoadingReset}
                                >{ isLoadingReset ? 'Loading...' : 'Reset Database' }</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}
 
export default Settings;