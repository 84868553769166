import { useEffect, useState, useRef } from 'react';
import { Accordion, Form } from 'react-bootstrap';
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import useFetch from '../useFetch';
import Input from "./Forms/Input";
import Select from "./Forms/Select";
import Textarea from "./Forms/Textarea";
import { Button, Card, Dropdown, Image, Table } from 'react-bootstrap';

const PaymentDetail = () => {    
    document.title = "Payment Details | Ark Club";
    
    const { id } = useParams();
    let navigate = useNavigate();

    const { response, isLoading, error: fetchError } = useFetch(process.env.REACT_APP_BASE_API_URL+'/payments/'+id);

    return ( 
        <div className="container-fluid">
            <div className="card shadow mb-4">
                <div className="card-header py-3 d-flex">
                    <h5 className="h5 mb-0 text-gray1-800 font-weight-bold text-primary mr-3">Payment Details</h5>
                    <h6 className="m-0 font-weight-bold text-primary ml-auto align-self-center text-right"><a href="#" onClick={() => navigate(-1)}>Back</a></h6>
                    <div className="clearfix"></div>
                </div>
                <div className="card-body pb-2">
                    { fetchError && <div className="alert-msg-danger"><h3 className="mb-0"><strong>{ fetchError }</strong></h3></div> }

                    { isLoading && <h5 className="text-center my-3">Loading Data</h5> }

                    { !fetchError && !isLoading ? (
                        <>
                            <div className="row">
                                <div className="col-xl-4 col-md-6 mb-3">
                                    <p className='mb-1'>Payment Date:</p>
                                    <p className='mb-0' style={{ fontWeight: '500' }}>{response?.data?.created_at_formatted}</p>
                                </div>
                                <div className="col-xl-4 col-md-6 mb-3">
                                    <p className='mb-1'>Code:</p>
                                    <p className='mb-0' style={{ fontWeight: '500' }}>{response?.data?.user?.referral_code}</p>
                                </div>
                                <div className="col-xl-4 col-md-6 mb-3">
                                    <p className='mb-1'>Name:</p>
                                    <p className='mb-0' style={{ fontWeight: '500' }}>{response?.data?.user?.name}</p>
                                </div>
                                <div className="col-xl-4 col-md-6 mb-3">
                                    <p className='mb-1'>Email:</p>
                                    <p className='mb-0' style={{ fontWeight: '500' }}><a href={`mailto:${response?.data?.user?.email}`}>{response?.data?.user?.email}</a></p>
                                </div>
                                <div className="col-xl-4 col-md-6 mb-3">
                                    <p className='mb-1'>Transfer ID:</p>
                                    <p className='mb-0' style={{ fontWeight: '500' }}>{response?.data?.transfer}</p>
                                </div>
                                <div className="col-xl-4 col-md-6 mb-3">
                                    <p className='mb-1'>Amount:</p>
                                    <p className='mb-0' style={{ fontWeight: '500' }}>{response?.data?.amount}</p>
                                </div>
                                <div className="col-xl-4 col-md-6 mb-3">
                                    <p className='mb-1'>Currency:</p>
                                    <p className='mb-0' style={{ fontWeight: '500' }}>{response?.data?.currency}</p>
                                </div>
                                <div className="col-xl-4 col-md-6 mb-3">
                                    <p className='mb-1'>Stripe Account ID:</p>
                                    <p className='mb-0' style={{ fontWeight: '500' }}>{response?.data?.destination}</p>
                                </div>
                                <div className="col-xl-4 col-md-6 mb-3">
                                    <p className='mb-1'>Destination Payment ID:</p>
                                    <p className='mb-0' style={{ fontWeight: '500' }}>{response?.data?.destination_payment}</p>
                                </div>
                            </div>
                        </>
                    ) : null}
                </div>
            </div>
        </div>
    );
}
 
export default PaymentDetail;