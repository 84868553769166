import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import useFetch from '../useFetch';
import { store } from "../redux/store";
import { removeUser } from "../redux/userSlice";

const AdminSidebar = ({setSidebarToggled, user}) => {    
    let navigate = useNavigate();

    const currentPath = useLocation().pathname.split('/')[1];

    const handleCloseSidebar = () => {
        setSidebarToggled(false)
    }

    const handleLogout = (e) => {
        e.preventDefault()
        
        fetch(process.env.REACT_APP_BASE_API_URL+'/logout', {
            method: 'POST',
            headers: { 
                "Accept": "application/json",
                "Authorization": "Bearer "+localStorage.getItem("token")
            },
        })
        
        store.dispatch(removeUser());
        setSidebarToggled(false)
        navigate('/');
    }
    
    return ( 
        <React.Fragment>
            <ul className="navbar-nav bg-white sidebar sidebar-dark">
                <li className={`nav-item ${currentPath == 'dashboard' ? 'active' : ''}`}>
                    <Link to={"/dashboard"} onClick={handleCloseSidebar} className="nav-link"><span>Dashboard</span></Link>
                </li>
                <li className={`nav-item ${currentPath == 'members' ? 'active' : ''}`}>
                    <Link to={"/members"} onClick={handleCloseSidebar} className="nav-link"><span>Members</span></Link>
                </li>
                <li className={`nav-item ${currentPath == 'payments' ? 'active' : ''}`}>
                    <Link to={"/payments"} onClick={handleCloseSidebar} className="nav-link"><span>Payments</span></Link>
                </li>
                <li className={`nav-item ${currentPath == 'track-admin' ? 'active' : ''}`}>
                    <Link to={"/track-admin"} onClick={handleCloseSidebar} className="nav-link"><span>Track</span></Link>
                </li>
                {/* <li className={`nav-item ${currentPath == 'charities' ? 'active' : ''}`}>
                    <Link to={"/charities"} onClick={handleCloseSidebar} className="nav-link"><span>Charities</span></Link>
                </li> */}
                <li className={`nav-item ${currentPath == 'settings' ? 'active' : ''}`}>
                    <Link to={"/settings"} onClick={handleCloseSidebar} className="nav-link"><span>Settings</span></Link>
                </li>
                <li className={`nav-item mt-auto highlight`}>
                    <Link to="/" onClick={handleLogout} className="nav-link"><span>Logout</span></Link>
                </li>
            </ul>
        </React.Fragment>
     );
}
 
export default AdminSidebar;