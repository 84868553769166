import { useState } from "react";
import { Outlet, Route, Routes } from "react-router-dom";
import { useSelector } from "react-redux";
import '../assets/css/sb-admin-2.min.css';
import '../assets/css/styles.css';

import Header from "./Header";
import Sidebar from "./Sidebar";

import Login from "./Login";
import Dashboard from "./Dashboard";
import Settings from "./Settings";
import MembersIndex from "./MembersIndex";
import MembersCreate from "./MembersCreate";
import MembersDetail from "./MembersDetail";
import MembersEdit from "./MembersEdit";
// import CharitiesIndex from "./CharitiesIndex";
// import CharitiesCreate from "./CharitiesCreate";
// import CharitiesEdit from "./CharitiesEdit";
import Unauthorized from "./Unauthorized";
import NotFound from "./NotFound";
import AdminTrack from "./AdminTrack";
import PaymentIndex from "./PaymentIndex";
import PaymentDetail from "./PaymentDetail";
import PendingMembersIndex from "./PendingMembersIndex";
import PendingMembersDetail from "./PendingMembersDetail";

const App = () => {
    const { user } = useSelector(state => state.user);

    return ( 
        <>
            <Routes>
                <Route path="/" element={<Login />} />
                
                <Route element={<AppLayout user={user} />} >
                    <Route path="/dashboard" element={user?.type == 'admin' ? <Dashboard /> : <Unauthorized />} />
                    <Route path="/settings" element={user?.type == 'admin' ? <Settings /> : <Unauthorized />} />
                    <Route path="/track-admin" element={user?.type == 'admin' ? <AdminTrack /> : <Unauthorized />} />
                    <Route path="/members" element={user?.type == 'admin' ? <MembersIndex /> : <Unauthorized />} />
                    <Route path="/members/create" element={user?.type == 'admin' ? <MembersCreate /> : <Unauthorized />} />
                    <Route path="/members/detail/:id" element={user?.type == 'admin' ? <MembersDetail /> : <Unauthorized />} />
                    <Route path="/members/edit/:id" element={user?.type == 'admin' ? <MembersEdit /> : <Unauthorized />} />
                    <Route path="/pending-members" element={user?.type == 'admin' ? <PendingMembersIndex /> : <Unauthorized />} />
                    <Route path="/pending-members/detail/:id" element={user?.type == 'admin' ? <PendingMembersDetail /> : <Unauthorized />} />
                    <Route path="/payments" element={user?.type == 'admin' ? <PaymentIndex /> : <Unauthorized />} />
                    <Route path="/payments/detail/:id" element={user?.type == 'admin' ? <PaymentDetail /> : <Unauthorized />} />
                    {/* <Route path="/charities" element={user?.type == 'admin' ? <CharitiesIndex /> : <Unauthorized />} />
                    <Route path="/charities/create" element={user?.type == 'admin' ? <CharitiesCreate /> : <Unauthorized />} />
                    <Route path="/charities/edit/:id" element={user?.type == 'admin' ? <CharitiesEdit /> : <Unauthorized />} /> */}
                    <Route path="*" element={<NotFound />} />
                </Route>
            </Routes>
        </>
    );
}

const AppLayout = ({user}) => {
    const [sidebarToggled, setSidebarToggled] = useState(false);

    return (
        <div className={`admin-app ${sidebarToggled ? 'app-sidebar-toggled' : ''}`} id="wrapper">
            <div id="content-wrapper" className="d-flex flex-column" onClick={() => { if (sidebarToggled) setSidebarToggled(false) }}>
                <div id="content">
                    {user?.type == 'admin' ? (
                        <Header 
                            sidebarToggled={sidebarToggled}
                            setSidebarToggled={setSidebarToggled}
                            />
                    ) : null }
                        
                    <div style={{ marginTop: '80px' }}>
                        <Outlet />
                    </div>
                </div>
            </div>

            {user?.type == 'admin' ? (
                <Sidebar 
                    setSidebarToggled={setSidebarToggled} 
                    user={user}  />
            ) : null}
        </div>
    )
};
 
export default App;