import { useEffect, useState } from 'react';
import { Link, useSearchParams } from "react-router-dom";
import useFetch from '../useFetch';
import Paginate from '../Paginate';
import DeletePopup from './DeletePopup';
import { Button, Card, Dropdown, Image, Table } from 'react-bootstrap';

const PaymentIndex = () => {
    document.title = "Payments | Ark Club";
    
    const [apiUrl, setApiUrl] = useState(process.env.REACT_APP_BASE_API_URL+'/payments');

    const [items, setItems] = useState([]);
    const [meta, setMeta] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    // const [deleteItem, setDeleteItem] = useState(null);

    const { response, isLoading, error: fetchError } = useFetch(apiUrl);

    useEffect(() => {
        setItems(response?.payments?.data ?? []);
        setMeta(response?.payments?.meta ?? []);
    }, [response])

    return (
        <div className="container-fluid">
            {/* {deleteItem ? <DeletePopup setItems={setItems} deleteItem={deleteItem} setDeleteItem={setDeleteItem} /> : null} */}
            
            <Card className="shadow mb-4">
                <Card.Header className="py-3 d-flex">
                    <h5 className="h5 mb-0 text-gray1-800 font-weight-bold text-primary mr-3">Payments</h5>
                    <h6 className="m-0 font-weight-bold text-primary ml-auto align-self-center text-right"><Link to="create">Add New</Link></h6>
                    <div className="clearfix"></div>
                </Card.Header>
                <Card.Body>
                    { fetchError && <div className="alert-msg-danger"><h3 className="mb-0"><strong>{ fetchError }</strong></h3></div> }

                    { isLoading && <h5 className="text-center my-3">Loading Data</h5> }
                    
                    { !fetchError && !isLoading ? (
                        <>
                            { items?.length == 0 ? <h5 className="text-center text-danger my-3">No data found{currentPage > 0 ? ' on this page' : ''}</h5> : (
                                <Table responsive bordered hover>
                                    <thead>
                                        <tr>
                                            <th style={{minWidth: '180px'}}>Payment Date</th>
                                            <th style={{minWidth: '180px'}}>Amount</th>
                                            <th style={{minWidth: '180px', width: '180px'}}>Code</th>
                                            <th style={{minWidth: '180px'}}>Name</th>
                                            <th style={{minWidth: '180px'}}>Email</th>
                                            <th style={{width: '1px' }}>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        { items?.map((item) => (
                                            <tr key={ item.id }>
                                                <td>{ item.created_at_formatted }</td>
                                                <td>{ item.amount } { item.currency }</td>
                                                <td><code>{ item.user.referral_code }</code></td>
                                                <td>{ item.user.name }</td>
                                                <td><a href={`mailto:${item.user.email}`}>{ item.user.email }</a></td>
                                                <td style={{padding: '0.3rem'}}>
                                                    <Link to={`detail/${item.id}`} className='btn btn-sm btn-secondary btn-block'>View</Link>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            ) }

                            {meta?.last_page > 1 ? (
                                <Paginate
                                    url={apiUrl}
                                    setUrl={setApiUrl}
                                    currentPage={currentPage}
                                    setCurrentPage={setCurrentPage}
                                    pageCount={meta?.last_page}
                                />
                            ) : null}

                            <i className="d-block text-center">{meta?.total ? `(${meta?.total} records found)` : null}</i>
                        </>
                    ) : null}
                </Card.Body>
            </Card>
        </div>
    );
}
 
export default PaymentIndex;