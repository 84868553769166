import { useEffect, useState, useRef } from 'react';
import { Button, Form } from 'react-bootstrap';
import { Link, useNavigate } from "react-router-dom";

const NotFound = () => {
    document.title = "Not Found | Ark Club";
    
    return (
        <div className="container-fluid py-2">
            <div className="text-center">
                <h3 className='text-danger pt-3 mb-0'>Page not found.</h3>
                <Button as={Link} to="/" className="mt-3" variant='info'>Back to home</Button>
            </div>
        </div>
    );
}
 
export default NotFound;